import FeaturesRenderText from '@/components/features/featuresRenderText';
import {useTranslation} from 'react-i18next';
import useUrlState from '@ahooksjs/use-url-state';
import {useReq} from '@/services/net/request';
import {getHelpCenterDetailApi} from '@/services/api/requestApi';
import {useMount} from 'ahooks';
import {BaseLoadingDisplay} from '@/components/base/baseLoadingDisplay';

import {Breadcrumbs, BreadcrumbItem} from '@nextui-org/react';
import RenderUtil from "@/utils/RenderUtil";

export default () => {
    const {t} = useTranslation();
    // 使用useUrlState钩子来获取URL中的状态信息
    const [urlState] = useUrlState<any>({});
    // 解构URL状态中的id和code
    const {id} = urlState || {};

    // 获取帮助列表详情
    const {data: helpCenterDetail = {}, run: getHelpCenterDetailReq, loading} = useReq(
        getHelpCenterDetailApi,
        {
            manual: true,
        },
    );
    useMount(() => {
        getHelpCenterDetailReq({
            id,
        });
    });

    return (
        <div>
            {/*面包屑*/}
            <div className="pt-[24px] font-pingfang-sc text-[14px] leading-[24px] tracking-[0%]">
                <Breadcrumbs>
                    <BreadcrumbItem href="/mine/helpArticle">{t('帮助文档')}</BreadcrumbItem>
                    <BreadcrumbItem>{helpCenterDetail?.title}</BreadcrumbItem>
                </Breadcrumbs>
            </div>

            <div className="pt-[40px] mt-4">
                <div
                    className="font-pingfang-sc font-[600] text-[28px] leading-[36px] tracking-[0%]">{helpCenterDetail?.title}</div>
                <div
                    className="font-pingfang-sc pt-[12px] mt-4 text-[14px] leading-[22px] tracking-[0%] text-[#86909C] font-[400]">
                    {t("发布于{{data}}", {
                        data: RenderUtil.formatDate(helpCenterDetail?.createTime, "YYYY-MM-DD HH:mm:ss")
                    })}
                </div>

            </div>
            {/*  内容区域*/}
            <div className="font-pingfang-sc leading-[24px] text-[16px] pt-[24px] text-[#292E38] font-[400]">
                <BaseLoadingDisplay loading={loading} list={loading ? [] : [1]}>
                    <FeaturesRenderText data={helpCenterDetail}/>
                </BaseLoadingDisplay>
            </div>
        </div>
    );
};
